import gsap from 'gsap';
import { useGSAP } from '@gsap/react';
import { heroVideo, smallHeroVideo } from '../utils';
import { useState, useEffect, useRef } from 'react';



const Hero = () => {

  const [videoSrc, setVideoSrc] = useState(window.innerWidth < 760 ? smallHeroVideo : heroVideo);
  const videoRef = useRef(null)
  

  const handleVideoSrcSet = () => {

      if (window.innerWidth < 760 ) {
        setVideoSrc(smallHeroVideo)
      } else {
        setVideoSrc(heroVideo)
      }
  }


  useEffect(() => {

    window.addEventListener('resize', handleVideoSrcSet)

    return () => {
      window.removeEventListener('resize', handleVideoSrcSet)
    }
  }, [])


  useEffect(() => {

    const videoElement = videoRef.current

    
    const handleVideoEnded = () => {

      videoElement.currentTime = 0
      videoElement.play()
    }


    videoElement.addEventListener('ended', handleVideoEnded)

    return () => {

      videoElement.removeEventListener('ended', handleVideoEnded)
    }

  }, [videoSrc])



  useGSAP(() => {

    gsap.to('#hero', { opacity: 1, delay: 2 })
    gsap.to('#cta', { opacity: 1, y: -50, delay: 2 })

  },[])





  

  return (
    
    <section className="w-full nav-height bg-black relative">

      <div className="h-5/6 w-full flex-center flex-col">

        <div id="hero" className="md:w-10/12 w-9/12">
              <video ref={videoRef} className="pointer-events-none" autoPlay muted playsInline={true} key={videoSrc}>
                <source src={videoSrc} type="video/mp4"/>
              </video>
        </div>

      </div>

      <div id="cta" className="flex flex-col items-center opacity-0 translate-y-20">
        
         <a 
          className="px-5 py-2 border-6 rounded-md flex items-center justify-center bg-customOrange text-white font-bold 
          hover:bg-customWhite hover:text-customOrange transition duration-300 ease-in-out"
          href="https://www.misli.az/epoz-qazan?utm_source=smartbee&utm_medium=landing-page-qizil-tarlasi&utm_campaign=qizil-tarlasi">
          İNDİ OYNA
        </a>
      </div>

    </section>


  )
}

export default Hero