

import hmv from "/assets/videos/HeroBig.mp4";
import smallmv from "/assets/videos/smallHero.mp4";
import highlightFirstmvMisli from "/assets/videos/highlight-1.mp4";
import highlightSectmvMisli from "/assets/videos/highlight-2.mp4";
import highlightThirdmvMisli from "/assets/videos/highlight-3.mp4";
import highlightFourthmvMisli from "/assets/videos/highlight-4.mp4";
import exploremvMisli from "/assets/videos/exploreMisli.mp4";
import framemvMisli from "/assets/videos/frameMisl.mp4";

import search from "/assets/images/search.svg";
import watch from "/assets/images/watch.svg";
import zeppelinIconMisli from "/assets/images/zeppelin-iconn.png";
import right from "/assets/images/right.svg";
import replay from "/assets/images/replay.svg";
import play from "/assets/images/play.svg";
import pause from "/assets/images/pause.svg";

import explore1 from "/assets/images/plinkoImage.jpg";
import explore2 from "/assets/images/zeppelinImage.jpg";
import QizilTarlasiMisliIcon from "/assets/images/qizil-tarlasi-1.png";
import frame from "/assets/images/frame.png";

export const heroVideo = hmv;
export const smallHeroVideo = smallmv;
export const highlightFirstVideoMisli = highlightFirstmvMisli;
export const highlightSecondVideoMisli = highlightSectmvMisli;
export const highlightThirdVideoMisli = highlightThirdmvMisli;
export const highlightFourthVideoMisli = highlightFourthmvMisli;
export const exploreVideoMisli = exploremvMisli;
export const frameVideoMisli = framemvMisli;

export const searchImg = search;
export const watchImg = watch;
export const zplIconMisli = zeppelinIconMisli
export const rightImg = right;
export const replayImg = replay;
export const playImg = play;
export const pauseImg = pause;


export const explore1Img = explore1;
export const explore2Img = explore2;
export const QizilTarlasiImg = QizilTarlasiMisliIcon;
export const frameImg = frame;